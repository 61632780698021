//Libraries
import React, { FunctionComponent } from 'react'
import Img from 'gatsby-image'
import cs from 'classnames'

//Types
import { ListSectionTypes } from '../z_components-types'

//Styles
import styles from './css/listSection.module.scss'

const ListSection: FunctionComponent<ListSectionTypes> = ({
  data,
  orientation,
}) => {
  
  return (
    <div
      className={cs(
        styles.listSection,
        orientation === 'reverse' && styles.reverse
      )}
    >
      <Img
        fluid={data.frontmatter.img.childImageSharp.fluid}
        alt="Support group image"
        className={cs(
          styles.image,
          //This is very hacky and would recommend trying to do it differently later DYLAN
          data.frontmatter.isImgContain === 'Yes' ? styles.contain : ''
        )}
      />
      <span>
        <h2 className={styles.name}>{data.frontmatter.name}</h2>
        <span dangerouslySetInnerHTML={{ __html: data.html }}></span>
      </span>
    </div>
  )
}

export default ListSection
