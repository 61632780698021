//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { PageWithLinksAndSectionTypes, SectionType } from './z_page-types'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import ListSection from '../components/templates/listSection'
import PageWithLinks from '../components/templates/pageWithLinks'

const NutritionalPrograms: FunctionComponent<PageWithLinksAndSectionTypes> = ({
  data,
}) => {
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  const isLinks: boolean =
    data.markdownRemark.frontmatter.links.length !== 0 ? true : false

  return (
    <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - Nutritional Programs</title>
          <link
            rel="canonical"
            href="https://ooa.csi.edu/nutritional-programs/"
          />
          <meta
            name="description"
            content="See what kind of Nutritional Programs the Office on Aging has to offer."
          />
        </Helmet>

      <Header />

      <Layout>
        <PageWithLinks
          html={data.markdownRemark.html}
          frontmatter={data.markdownRemark.frontmatter}
        >
          {data.markdownRemark.html && <hr />}

          {edges
            .sort((a, b) =>
              a.node.frontmatter.name < b.node.frontmatter.name ? -1 : 1
            )
            .map((program: SectionType, index: number) => (
              <div key={program.node.frontmatter.name}>
                <ListSection
                  data={program.node}
                  orientation={index % 2 !== 0 ? 'reverse' : 'normal'}
                />
                {(isLinks || index + 1 !== edges.length) && <hr />}
              </div>
            ))}
        </PageWithLinks>
      </Layout>

      <Footer />
    </FontSizeProvider>
  )
}

export default NutritionalPrograms

export const nutritionalProgramsQuery = graphql`
  query nutritionalPrograms {
    markdownRemark(
      frontmatter: { template_key: { eq: "nutritional-programs-page" } }
    ) {
      html
      frontmatter {
        title
        links {
          link_name
          link
        }
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template_key: { eq: "nutritional-program" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            isImgContain
            img {
              childImageSharp {
                fluid(maxWidth: 1700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
